<template>
    <Modal :mask-closable="false" :closable="false" v-model="isShow" title="查看物流" :scrollable="true" width="800" :loading="ajaxLoading">
        <div class="relative express_message">
            <Timeline>
                <TimelineItem class="color_gray" color="#e0e0e0">
                    <Icon class="fontsize_24" type="md-radio-button-on" slot="dot"></Icon>
                    <h4>收货地址</h4>
                    <div>{{ detail.address || '-' }}</div>
                </TimelineItem>
                <TimelineItem v-for="(info, idx) in detail.expressList || []" :key="info.id" :color="idx == 0 ? 'green' : '#e0e0e0'" :class="{ color_gray: idx > 0 }">
                    <Icon :class="{ fontsize_24: idx > 0, fontsize_30: idx == 0 }" :type="idx == 0 ? 'md-car' : 'md-radio-button-on'" slot="dot"></Icon>
                    <div class="time">{{ info.time }}</div>
                    <h4 v-if="isNotEmptyString(info.title)">{{ info.title }}</h4>
                    <div v-if="isNotEmptyString(info.note)" class="item">{{ info.note }}</div>
                </TimelineItem>
            </Timeline>
        </div>
        <div slot="footer" class="relative width_100_percent flex align_center justify_center">
            <Button type="primary" ghost @click="onCancel">取消</Button>
        </div>
    </Modal>
</template>
<script>
import { isNotEmptyString } from '@/lib/util';

export default {
    name: 'expressMessage',
    props: ['value', 'data'],
    data() {
        return {
            isShow: false,
            detail: {},
        };
    },
    watch: {
        value(newValue) {
            this.isShow = newValue;
        },
        data(newValue) {
            this.detail = newValue;
        },
    },
    mounted() {
        //
    },
    methods: {
        isNotEmptyString,
        onCancel() {
            this.isShow = false;
            this.$emit('input', false);
        },
    },
};
</script>
<style lang="less" scoped>
.express_message {
    padding: 40px;
    height: 650px;
    overflow: auto;

    & /deep/ .ivu-timeline {
        & span,
        & div {
            font-size: 14px;
        }
        margin-left: 150px;
    }
    .time {
        position: absolute;
        top: 0;
        right: calc(100% + 10px);
        white-space: nowrap;
        font-size: 14px;
    }
    .color_gray {
        color: #c5c8ce;
    }
}
</style>
